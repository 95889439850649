import { template as template_45e022e8cea04d7098235f81a579fdc3 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import emoji from "discourse/helpers/emoji";
import escape from "discourse-common/lib/escape";
import I18n from "discourse-i18n";
const title = (description, endsAt, timezone)=>{
    let content = escape(description);
    if (endsAt) {
        const until = moment.tz(endsAt, timezone).format(I18n.t("dates.long_date_without_year"));
        content += `\n${I18n.t("until")} ${until}`;
    }
    return content;
};
const UserStatusBubble = template_45e022e8cea04d7098235f81a579fdc3(`
  <div class="user-status-background">
    {{emoji
      @status.emoji
      title=(title @status.description @status.ends_at @timezone)
      alt=(concat ":" @status.emoji ":")
    }}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserStatusBubble;
