import { template as template_79462c8d06c0489a8a96abebf7bfb874 } from "@ember/template-compiler";
const FKLabel = template_79462c8d06c0489a8a96abebf7bfb874(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
